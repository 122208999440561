import adminLibrary from "img/admin_library.jpg";
import "./members.scss";
import members from "utils/members_list";
import tedxHoldup from "img/tedx-holdup.jpeg";

const Members = () => {
  return (
    <div id="members">
      <div className="image-banner">
        <img src={tedxHoldup} alt="" />
      </div>
      <p className="team-year">
        <span>2022 </span>
        TEAM
      </p>
      <div className="people">
        {members.map(({ img, name, team }) => (
          <div className="member">
            <img src={img} alt={name} width="600" loading="lazy" />
            <p className="name">{name}</p>
            <p className="team">{team}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Members;
