// libraries
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ROUTES from "utils/Routes.js";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          {ROUTES.map((route) => (
            <Route path={route.path} element={route.page} key={route.label} />
          ))}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
