import "./about-tedx.scss";

const AboutTedx = () => {
  return (
    <section id="about-tedx">
      <p>
        About
        <span> TEDx </span>
      </p>
      <article>
        In the spirit of ideas worth spreading, TEDx is a program of local,
        self-organized events that bring people together to share a TED-like
        experience. At a TEDx event, TED Talks video and live speakers combine
        to spark deep discussion and connection. These local, self-organized
        events are branded TEDx, where x = independently organized TED event.
        The TED Conference provides general guidance for the TEDx program, but
        individual TEDx events are self-organized. (Subject to certain rules and
        regulations.)
      </article>
      <button>read more</button>
    </section>
  );
};

export default AboutTedx;
