import speakers_list from "utils/speakers_list.js";
import adminLibrary from "img/admin_library.jpg";
import tedxHoldup from "img/tedx-holdup.jpeg";
import "./speakers.scss";

const Speakers = () => {
  return (
    <div id="speakers">
      <div className="image-banner">
        <img src={tedxHoldup} alt="" />
      </div>
      <h2 className="speakers-header">Speakers</h2>
      <div className="speakers-list">
        {speakers_list.map(({ img, name, bio }) => (
          <div className="speaker">
            <div className="speaker-img-parent">
              <img src={img} alt="" />
            </div>
            <div className="speaker-bio-parent">
              <h2>{name}</h2>
              <article>{bio}</article>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Speakers;
