import ROUTES from "utils/Routes.js";
import { NavLink } from "react-router-dom";
import "./sidebar.scss";
import CloseButton from "components/close/CloseButton";

const Sidebar = ({ toggleState, toggleSlide }) => {
  return (
    <div className={`sidebar ${toggleState}`}>
      <div className="nav">
        <CloseButton toggleSlide={toggleSlide} />
        <nav>
          {ROUTES.map(({ path, label, internal }) =>
            internal ? (
              <a
                href="https://forms.office.com/r/HTSU0nkQUZ"
                target="_blank"
                rel="noopener noreferrer"
              >
                {label}
              </a>
            ) : (
              <NavLink to={path} key={label}>
                {label}
              </NavLink>
            )
          )}
        </nav>
      </div>
      <div className="overlay" onClick={() => toggleSlide()}></div>
    </div>
  );
};

export default Sidebar;
