// components
import Home from "pages/home/Home.js";
import AboutUs from "pages/about-us/AboutUs.js";
import Speakers from "pages/speakers/Speakers.js";
import Members from "pages/members/Members.js";
import Sponsors from "pages/sponsors/Sponsors.js";

// const ROUTES = [
//   { path: "/", page: <Home />, label: "Home" },
//   { path: "about-us", page: <AboutUs />, label: "About Us" },
//   { path: "speakers", page: <Speakers />, label: "Speakers" },
//   { path: "members", page: <Members />, label: "Members" },
//   { path: "tickets", page: <Tickets />, label: "Tickets" },
// ];

const ROUTES = [
  { path: "/", page: <Home />, label: "Home", internal: false },
  { path: "about-us", page: <AboutUs />, label: "About Us", internal: false },
  { path: "speakers", page: <Speakers />, label: "Speakers", internal: false },
  { path: "sponsors", page: <Sponsors />, label: "Sponsors", internal: false },
  { path: "members", page: <Members />, label: "Members", internal: false },
  {
    path: "https://forms.office.com/r/HTSU0nkQUZ",
    page: <Home />,
    label: "Tickets",
    internal: true,
  },
];

export default ROUTES;
