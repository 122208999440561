import catherineDelight from "img/members/kate.jpg";
import justinAdomakoh from "img/members/Justin Adomakoh.jpg";

// curation
import wepeaBuntugu from "img/members/Wepea_Buntugu.jpg";
import maxineBrenya from "img/members/Maxine_Brenya.jpg";
import nanaYaaOwusu from "img/members/Nana_Yaa_Owusu.jpg";
import rachaelMudendi from "img/members/Rachael Mugendi.jpg";

// design
import ewuraAbenaAsmah from "img/members/ewurabena.jpg";
import faddalIbrahim from "img/members/Faddal Ibrahim.jpg";
import divineIshmiwe from "img/members/Divine Ishimwe.jpg";
import cliffordYeboah from "img/members/Clifford.jpg";

// events
import keziaAsare from "img/members/Kezia Asare.jpg";
import noahAdasi from "img/members/noah.jpg";
import niceCaillie from "img/members/Nice Cailie Ineza.jpg";
import brightAntwi from "img/members/Bright Anim Antwi.jpg";

// events
import ayeyiOheneAdu from "img/members/Ayeyi_Ohene-Adu.jpg";
import selasieAzumah from "img/members/selasie.jpg";
import hortenseUmubyei from "img/members/hortense.jpg";
import tsatsuAgbettor from "img/members/Tsatsu_Agbettor.jpg";
import samanthaReindorf from "img/members/samantha.jpg";

// finance
import ericGadzi from "img/members/Eric_Gadzi.JPG";
import tinasheKanukai from "img/members/Tinashe_Kanukai.jpg";

// video
import russellOkine from "img/members/russel.jpeg";
import enochAho from "img/members/Enoch.jpg";
import emmanuellaTurkson from "img/members/Emmanuella_Abledu-Turkson.jpg";
import alosiusAkonteh from "img/members/Alosius Akonteh.jpg";

const members = [
  {
    img: catherineDelight,
    name: "Catherine Delight",
    team: "Licensee & Lead Organizer",
  },
  {
    img: justinAdomakoh,
    name: "Justin Adomakoh",
    team: "Co-Organizer",
  },
  {
    img: wepeaBuntugu,
    name: "Wepea Buntugu",
    team: "Curation Team",
  },
  {
    img: maxineBrenya,
    name: "Maxine Brenya",
    team: "Curation Team",
  },
  {
    img: nanaYaaOwusu,
    name: "Nana Yaa Owusu",
    team: "Curation Team",
  },
  {
    img: rachaelMudendi,
    name: "Rachael Mudendi",
    team: "Curation Team",
  },
  {
    img: ewuraAbenaAsmah,
    name: "Ewura Abena Asmah",
    team: "Design Team Lead",
  },
  {
    img: faddalIbrahim,
    name: "Faddal Ibrahim",
    team: "Design and Website Team",
  },
  {
    img: divineIshmiwe,
    name: "Divine Ishmiwe",
    team: "Design Team",
  },
  {
    img: cliffordYeboah,
    name: "Clifford Yeboah",
    team: "Design Team",
  },
  {
    img: keziaAsare,
    name: "Kezia Asare",
    team: "Event Management Team Lead",
  },
  {
    img: noahAdasi,
    name: "Noah Adasi",
    team: "Event Management Team",
  },
  {
    img: brightAntwi,
    name: "Bright Antwi",
    team: "Event Management Team",
  },
  {
    img: niceCaillie,
    name: "Nice Caillie",
    team: "Event Management Team",
  },
  {
    img: ericGadzi,
    name: "Eric Gadzi",
    team: "Finance Lead",
  },
  {
    img: tinasheKanukai,
    name: "Tinashe Kanukai",
    team: "Finance Co-Lead",
  },
  {
    img: ayeyiOheneAdu,
    name: "Ayeyi Ohene-Adu",
    team: "Marketing Team Lead",
  },
  {
    img: selasieAzumah,
    name: "Selasie Azumah",
    team: "Marketing Team Co-Lead",
  },
  {
    img: hortenseUmubyei,
    name: "Hortense Umubyei",
    team: "Marketing Team",
  },
  {
    img: tsatsuAgbettor,
    name: "Tsatsu Agbettor",
    team: "Marketing Team",
  },
  {
    img: samanthaReindorf,
    name: "Samantha Reindorf",
    team: "Marketing Team",
  },
  {
    img: alosiusAkonteh,
    name: "Alosius Akonteh",
    team: "Photography Team Lead",
  },
  {
    img: russellOkine,
    name: "Russell Okine",
    team: "Videography Team Lead",
  },
  {
    img: emmanuellaTurkson,
    name: "Emmanuella Turkson",
    team: "Videography Team",
  },
  {
    img: enochAho,
    name: "Enoch Aho",
    team: "Videography Team",
  },
];

export default members;
