import ROUTES from "utils/Routes.js";
import { NavLink } from "react-router-dom";
import "./header.scss";
import tedx_logo from "img/tedx_16_9.png";
import Hamburger from "components/hamburger/Hamburger";
import Sidebar from "components/sidebar/Sidebar";
import { useState } from "react";

const Header = () => {
  const [toggleState, setToggleState] = useState("close");
  const toggleSlide = () => {
    toggleState === "open" ? setToggleState("close") : setToggleState("open");
  };
  return (
    <header>
      <Hamburger toggleSlide={toggleSlide} />
      <img src={tedx_logo} alt="tedxashesiuniversity logo" />
      <nav className="desktop">
        {ROUTES.map(({ path, label, internal }) =>
          internal ? (
            <a
              href="https://forms.office.com/r/HTSU0nkQUZ"
              target="_blank"
              rel="noopener noreferrer"
            >
              {label}
            </a>
          ) : (
            <NavLink to={path} key={label}>
              {label}
            </NavLink>
          )
        )}
      </nav>
      <Sidebar toggleState={toggleState} toggleSlide={toggleSlide} />
    </header>
  );
};

export default Header;
