import AboutTedx from "components/about-tedx/AboutTedx";
import MoreDetails from "components/more-details/MoreDetails";
import wanderers from "img/wanderers.png";
import "./home.scss";
import PastEvents from "components/past-events/PastEvents";

const Home = () => {
  return (
    <div id="home">
      <img src={wanderers} alt="tedx-theme" loading="lazy" />
      <MoreDetails />
      <AboutTedx />
      <PastEvents />
    </div>
  );
};

export default Home;
