import "./more-details.scss";

const MoreDetails = () => {
  return (
    <section id="more-details">
      <article>9th April 2022</article>
      <p>11:00AM - 4:00PM GMT</p>
      <button>
        <a
          href="https://forms.office.com/r/HTSU0nkQUZ"
          target="_blank"
          rel="noopener noreferrer"
        >
          click here for tickets
        </a>
      </button>
    </section>
  );
};

export default MoreDetails;
