import simbaAsamoah from "img/speakers/simba.jpg";
import tobiasWoode from "img/speakers/tobi.jpg";
import prudenceKariuki from "img/speakers/prudence.jpg";
import obedAlloteyBagington from "img/speakers/obed.jpg";
import mosesQuansah from "img/speakers/moses.jpg";
import ajaMonet from "img/speakers/aja.jpg";
import jewelNanaThompson from "img/speakers/jewel.jpg";
import sylviaArthur from "img/speakers/sylvia.jpg";
import innocentFaraiChikwanda from "img/speakers/innocent.jpg";
import faithRovinaNgala from "img/speakers/faith.jpg";
import samuelFordjuor from "img/speakers/samuel.jpg";

const speakers_list = [
  {
    img: faithRovinaNgala,
    name: "Faith Rovina Ngala",
    bio: "Faith is a freshman at Ashesi University passionate about leadership and creating a positive impact in her society. She believes that a good leader is a follower and a reflection of what they say they believe. ",
  },

  {
    img: tobiasWoode,
    name: "Tobias Woode",
    bio: "Tobias Woode, preferably called Toby Woode is a young ambitious and extroverted leader rooted in his Christian faith. He recently ended his tenure as the Ashesi Student Council president and hopes to pursue his passions of people management and education through various social initiatives. ",
  },
  {
    img: obedAlloteyBagington,
    name: "Obed Allotey Bagington",
    bio: "Obed, an Electrical/Electronic Engineering student of Ashesi University, believes that when the suppression of the willful expression of the self leads to the degradation of society. He loves to use every opportunity he can get to create meaning in people's lives through self-expression. ",
  },

  {
    img: samuelFordjuor,
    name: "Samuel Fordjuor",
    bio: "Samuel Fordjuor is currently serving as a faculty intern with the Business Administration Department at Ashesi University. He supports his faculty leads facilitate final year courses, namely, the Applied Project and Undergraduate Thesis class. Samuel is interested in the process of self-development geared towards becoming a better version of himself. In his spare time, he enjoys playing basketball. ",
  },
  {
    img: mosesQuansah,
    name: "Moses Quansah",
    bio: "Moses is a dreamer that believes there are a lot of opportunities in life. He was born in Cape Coast, a city in Ghana, and currently lives in Berekuso, Eastern Region. He works as a security guard at Ashesi University. He is a baker in his free time and hopes to open a bakery together with his brother.  ",
  },
  {
    img: sylviaArthur,
    name: "Sylvia Arthur",
    bio: "Sylvia Arthur is a narrative nonfiction writer and the Founder of the Library of Africa and The African Diaspora (LOATAD), a library, archive, writing residency and research institute dedicated to the work of African and Diasporan writers from the late 19th century to the present day. Her work has appeared in the Lit Hub, The Guardian, The British Journalism Review and on the BBC website. Sylvia has also been collecting books for about 20 years and owns an extensive private collection of unique books and other related items. ",
  },
  {
    img: simbaAsamoah,
    name: "Simba Asamoah",
    bio: "Simba Asamoah is a junior majoring in Business Administration at Ashesi University. She is very keen on the arts, an avid reader, a movie buff, and a budding writer. She is also extremely passionate and vocal about mental health, a motivation for joining the Melton Foundation, where she collaborates with people across the world to solve challenges. She hopes to promote and educate people on the importance of mental health which she believes is equally as important as physical health. On the side, she is working on a script and her first novel. Fun fact about Simba: she can say every line in Shrek word for word. ",
  },

  {
    img: innocentFaraiChikwanda,
    name: "Innocent Farai Chikwanda",
    bio: "Innocent Farai Chikwanda is an enthusiastic multi-dimensional problem solver interested in finding, communicating, and engineering sustainable solutions to problems that transcend geography, gender, ethnicity, race, and academic discipline. He loves speaking, but what values listening more; in listening, He finds the stature of his ideas broadened, and the nature of their quality tested. He has had the privilege to be the President of the Zimbabwe Child Parliament, a student at Ashesi, a brother, a son, and doubtlessly whatever God will allow next. ",
  },

  {
    img: jewelNanaThompson,
    name: "Jewel Nana Thompson",
    bio: "Jewel Nana Thompson is an award-winning social impact strategist. She is an Assistant Lecturer of Entrepreneurship and Researcher at Ashesi University where she is the Entrepreneurship Concentration lead for the business department and lead of the Community Entrepreneurship Program. She is the co-founder of the Ashesi Venture Incubator of the NEXT (New Entrepreneurs Xchange for Transformation) Idea to Impact Project collaboration between Ashesi and the Massachusetts Institute of Technology (MIT) D-Lab. She has been awarded the Georgia State University’s 40 under 40 award, YNPN and Georgia Center for Non-Profits 30 Under 30 Award, the Power 30 Under 30 Atlanta, and the Miss Africa Humanitarian Award. She is the founder of EcoLaunch, a Ghanaian-based business advisory firm that supports early growth African social impact startups that seek to expand their offerings globally. She is an avid lover of the arts and its intersection with technology and is committed to developing leadership programs for emerging youth. She is currently pursuing her Ph.D. at the University of Ghana. ",
  },
  {
    img: ajaMonet,
    name: "Aja Monet",
    bio: "Aja Monet is a surrealist blues poet, storyteller, and organizer born and raised in Brooklyn, NY, USA. She won the legendary Nuyorican Poets Cafe Grand Slam poetry award title in 2007 and Aja Monet follows in the long legacy and tradition of poets participating and assembling in social movements. Her first full collection of poems is titled, My Mother Was a Freedom Fighter on Haymarket Books. Her poems explore gender, race, migration, and spirituality. In 2018, she was nominated for an NAACP Literary Award for Poetry and in 2019 was awarded the Marjory Stoneman Douglas Award for Poetry for her cultural organizing work in South Florida. Aja Monet cofounded a political home for artists and organizers called, Smoke Signals Studio. She also founded and facilitated “Voices: Poetry for the People,” a workshop and collective in collaboration with Community Justice Project and Dream Defenders. She is currently working on her next full collection of poems entitled, Florida Water. Aja Monet currently serves as the Artistic Creative Director for V-Day, a global movement to end violence against all women and girls where she is creating a new work of art featuring and connecting voices from the African continent and the diaspora. ",
  },
  {
    img: prudenceKariuki,
    name: "Prudence Kariuki",
    bio: "Prudence is a 22-year-old student at Ashesi University pursuing Bachelors in Management Information System. She enjoys jigsaw puzzles, drawing animations, hiking, and travelling in addition to her unique skill set in market and research analysis. She is fearless, audacious, and bold, attributes that have positioned her at the right angle of opportunities coming her way. She`s managed to serve as a Resident Assistant, Vice President of Mastercard Scholars and Curriculum Development Intern; where she worked with Dr. Takako , to create a Computer Science curriculum for Musizi University in Uganda. She thrives in diverse environments for instance her experience at the International Business internship with X-Culture in USA. Her interactions with students from USA, Morocco, Russia, and France have groomed her international experiences by teaching her the power of understanding and accommodating different facets of people. Her last experience giving such an important talk was in 2018, as a valedictorian and she hopes to inspire everyone as a TEDx speaker through her life`s journey as a wanderer. ",
  },
];

export default speakers_list;
