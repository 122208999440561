import ao from "img/sponsors/ao.jpg";
import ashlab from "img/sponsors/ashlab.jpg";
import bad from "img/sponsors/bad.jpg";
import blues from "img/sponsors/blues.jpg";
import ed from "img/sponsors/ed.jpg";
import genkey from "img/sponsors/genkey.png";
import hillburi from "img/sponsors/hillburi.png";
import newsbanner from "img/sponsors/newsbanner.jpg";
import npontu from "img/sponsors/npontu.jpg";
import peduase from "img/sponsors/peduase.png";

const sponsors_list = [
  {
    img: ao,
    name: "Ashesi Admisions Office",
  },
  {
    img: ashlab,
    name: "Ashesi Design Lab",
  },
  {
    img: bad,
    name: "Ashesi Business Administration Department",
  },
  {
    img: blues,
    name: "Blue Skies",
  },
  {
    img: ed,
    name: "Ashesi Engineering Department",
  },
  {
    img: genkey,
    name: "Genkey",
  },
  {
    img: hillburi,
    name: "Hillburi Aburi",
  },
  {
    img: newsbanner,
    name: "Calbank",
  },
  {
    img: npontu,
    name: "Npontu Technologies",
  },
  {
    img: peduase,
    name: "Peduase Resort",
  },
];

export default sponsors_list;
