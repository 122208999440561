import "./past-events.scss";
import AdversitiesAsAsset from "../../img/adverties-as-asset.jpeg";

const PastEvents = () => {
  return (
    <div id="past-events">
      <p>
        PAST <span>E</span>VENTS
      </p>
      <br />
      <br />
      <article className="image-frame">
        <img
          src={AdversitiesAsAsset}
          alt="adversities as asset"
          loading="lazy"
        />
        <button>
          <a
            href="https://www.ted.com/tedx/events/39261"
            target="_blank"
            rel="noreferrer"
          >
            Event Details
          </a>
        </button>
      </article>
    </div>
  );
};

export default PastEvents;
