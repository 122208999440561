import "./sponsors.scss";
import sponsors_list from "utils/sponsors_list";

const Sponsors = () => {
  return (
    <div id="sponsors">
      <div className="banner">sponsors</div>
      <div className="sponsors_div">
        {sponsors_list.map((sponsor) => (
          <div className="sponsor">
            <img src={sponsor.img} alt="" />
            <p>{sponsor.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sponsors;
