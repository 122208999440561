import tedx_logo from "img/tedx_16_9.png";
import { NavLink } from "react-router-dom";
import "./footer.scss";

const Footer = () => {
  return (
    <footer>
      <section>
        <img src={tedx_logo} alt="" />
        <div>
          <NavLink to="/">Contact</NavLink>
          <NavLink to="/">Speaker</NavLink>
          <NavLink to="/">Be a Volunteer</NavLink>
          <a
            href="https://forms.office.com/r/HTSU0nkQUZ"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tickets
          </a>
        </div>
      </section>
      <section>
        <a href="https://twitter.com/TedxAshesi">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="https://www.instagram.com/ashesiuniversitytedx/">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://www.facebook.com/TEDxAshesiUniversity">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="https://www.linkedin.com/company/tedxashesiuniversity/">
          <i className="fab fa-linkedin"></i>
        </a>
      </section>
      <section>
        &copy; 2022 TEDxAshesiUniversity. This Independent TEDx Event is
        Operated Under Licence From TED
      </section>
    </footer>
  );
};

export default Footer;
